/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';
import { Sheet, FormControl, FormLabel, Input } from '@mui/joy';
import { useLocation } from 'react-router-dom';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab from '@mui/joy/Tab';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import TabPanel from '@mui/joy/TabPanel';
import Box from '@mui/joy/Box';
import {
  useGetCodeDetailsMutation,
  useUpdateCodeMutation,
} from '../../api/CodeApi';
import { Code } from '../../models/code';
import Grid from '@mui/joy/Grid';
import AspectRatio from '@mui/joy/AspectRatio';
import ImageIcon from '@mui/icons-material/Image';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import ConfirmationSnackbar from '../ConfirmationSnackbar';
import useIsUserAuthorized from '../../Hooks/useIsUserAuthorized';
import PublicIcon from '@mui/icons-material/Public';
import Chip from '@mui/joy/Chip';
import './index.scss';

const EditItem: React.FC = (): React.ReactElement => {
  const location = useLocation();
  const { selected } = location.state;

  const [index, setIndex] = useState(0);
  const [codeDetails, setCodeDetails] = useState<Code[]>([]);
  const [getCodeDetails] = useGetCodeDetailsMutation();
  const [updatePlu] = useUpdateCodeMutation();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const isUserAuthorized = useIsUserAuthorized();

  useEffect(() => {
    if (selected && selected.length > 0) {
      getCodeDetails({ pluCodes: selected })
        .unwrap()
        .then((response: Code[]) => {
          setCodeDetails(response);
        });
    }
  }, [selected, getCodeDetails]);

  const handleInputChange = (idx: number, field: string, value: string) => {
    const updatedCodeDetails = [...codeDetails];
    updatedCodeDetails[idx] = { ...updatedCodeDetails[idx], [field]: value };
    setCodeDetails(updatedCodeDetails);
  };

  const handleUpdate = () => {
    const updatePromises = codeDetails.map((codeDetail) =>
      updatePlu({
        plu: codeDetail.plu || '',
        descripEn: codeDetail.descripEn || '',
        descripKo: codeDetail.descripKo || '',
        // sku: codeDetail.sku || '',
        // productCode: codeDetail.productCode || '',
        // pluOrganic: codeDetail.pluOrganic || '',
        // pluLocal: codeDetail.pluLocal || '',
        // pluBox: codeDetail.pluBox || '',
        // department: codeDetail.department || '',
        // category: codeDetail.category || '',
        // upc: codeDetail.upc || '',
        // image: codeDetail.image || '',
      }).unwrap(),
    );

    Promise.all(updatePromises)
      .then(() => {
        setSnackbarMessage('Update successful');
        setOpenSnackbar(true);
      })
      .catch(() => {
        setSnackbarMessage('Error during update');
        setOpenSnackbar(true);
      });
  };

  const renderDetail = useMemo(() => {
    return codeDetails.map((codeDetail: Code, idx: number) => (
      <Grid container spacing={2} key={idx}>
        <Grid lg={8} md={8} sm={12} xs={12}>
          <FormControl>
            <FormLabel>PLU</FormLabel>
            {isUserAuthorized ? (
              <Input
                disabled={true}
                value={codeDetail.plu || ''}
                onChange={(e) => handleInputChange(idx, 'plu', e.target.value)}
                placeholder="Enter PLU"
              />
            ) : (
              <Typography>{codeDetail.plu || 'N/A'}</Typography>
            )}
          </FormControl>

          <FormControl>
            <FormLabel>Description EN</FormLabel>
            {isUserAuthorized ? (
              <Input
                value={codeDetail.descripEn || ''}
                onChange={(e) =>
                  handleInputChange(idx, 'descripEn', e.target.value)
                }
                placeholder="Enter English Description"
              />
            ) : (
              <Typography>{codeDetail.descripEn || 'N/A'}</Typography>
            )}
          </FormControl>

          <FormControl>
            <FormLabel>Description KO</FormLabel>
            {isUserAuthorized ? (
              <Input
                value={codeDetail.descripKo || ''}
                onChange={(e) =>
                  handleInputChange(idx, 'descripKo', e.target.value)
                }
                placeholder="Enter Korean Description"
              />
            ) : (
              <Typography>{codeDetail.descripKo || 'N/A'}</Typography>
            )}
          </FormControl>

          <div className="pluGroup">
            <div className="pluOrganic">
              <Typography level="title-md">PLU Organic</Typography>
              <Typography level="body-md">
                {codeDetail.pluOrganic || 'N/A'}
              </Typography>
            </div>
            <div className="pluLocal">
              <Typography level="title-md">PLU Local</Typography>
              <Typography level="body-md">
                {codeDetail.pluLocal || 'N/A'}
              </Typography>
            </div>
            <div className="pluBox">
              <Typography level="title-md">PLU Box</Typography>
              <Typography level="body-md">
                {codeDetail.pluBox || 'N/A'}
              </Typography>
            </div>
          </div>

          <FormControl>
            <FormLabel>Department</FormLabel>
            {isUserAuthorized ? (
              <Input
                value={codeDetail.deptCode || ''}
                onChange={(e) =>
                  handleInputChange(idx, 'department', e.target.value)
                }
                placeholder="Enter Department"
              />
            ) : (
              <Typography>{codeDetail.deptCode || 'N/A'}</Typography>
            )}
          </FormControl>

          <FormControl>
            <FormLabel>Category</FormLabel>
            {isUserAuthorized ? (
              <Input
                value={codeDetail.categoryCode || ''}
                onChange={(e) =>
                  handleInputChange(idx, 'category', e.target.value)
                }
                placeholder="Enter Category"
              />
            ) : (
              <Typography>{codeDetail.categoryCode || 'N/A'}</Typography>
            )}
          </FormControl>
        </Grid>
        <Grid lg={4} md={8} sm={12} xs={12}>
          <AspectRatio maxHeight={250}>
            <ImageIcon sx={{ fontSize: '3rem', opacity: 0.2 }} />
          </AspectRatio>
        </Grid>
      </Grid>
    ));
  }, [codeDetails, isUserAuthorized]);

  return (
    <Sheet className="editPlu" variant="outlined">
      <Tabs
        aria-label="tabs"
        value={index}
        onChange={(event, newValue) => setIndex(newValue as number)}
      >
        <TabList
          variant="soft"
          sx={{
            display: 'flex',
            overflowX: 'auto',
            whiteSpace: 'nowrap',
            '&::-webkit-scrollbar': {
              display: 'block',
              width: '4px',
              height: '5px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              borderRadius: '4px',
            },
          }}
        >
          {codeDetails.map((codeDetail: Code, idx: number) => (
            <Tab
              key={idx}
              indicatorPlacement="top"
              sx={{ flex: '0 0 auto', minWidth: '150px', textAlign: 'center' }}
            >
              <ListItemDecorator />
              {codeDetail?.codeType === 'Global' ? (
                <Chip
                  color="primary"
                  variant="soft"
                  startDecorator={<PublicIcon />}
                >
                  Global
                </Chip>
              ) : null}
              {`${codeDetail.plu} ${
                codeDetail?.descripKo ? codeDetail.descripKo : ''
              }`}
            </Tab>
          ))}
        </TabList>

        <Box className="details">
          {codeDetails.map((_, idx: number) => (
            <TabPanel key={idx} value={idx}>
              {renderDetail[idx]}
              {isUserAuthorized && (
                <Button onClick={handleUpdate}>Submit</Button>
              )}
            </TabPanel>
          ))}
          <ConfirmationSnackbar
            message={snackbarMessage}
            open={openSnackbar}
            setOpen={setOpenSnackbar}
          />
        </Box>
      </Tabs>
    </Sheet>
  );
};

export default EditItem;
