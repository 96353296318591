import React from 'react';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { Box, Chip, FormControl, FormLabel } from '@mui/joy';

interface SelectOption {
  value: string;
}

type StoreRoleTypeProps = {
  storeRoles: string[];
};

const StoreRolesSelect: React.FC<StoreRoleTypeProps> = ({
  storeRoles,
}): React.ReactElement => {
  return (
    <div className="storeRolesSelect">
      <FormLabel>Store Roles</FormLabel>
      <FormControl>
        <Select
          disabled={true}
          multiple
          value={storeRoles}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', gap: '0.25rem' }}>
              {selected.map((option: SelectOption, index: number) => (
                <Chip key={index} variant="soft" color="primary">
                  {option.value}
                </Chip>
              ))}
            </Box>
          )}
          sx={{
            minWidth: '15rem',
          }}
          slotProps={{
            listbox: {
              sx: {
                width: '100%',
              },
            },
          }}
        >
          <Option value="admin">Admin</Option>
          <Option value="manager">Manager</Option>
          <Option value="analytics">Analytics</Option>
          <Option value="label">Label</Option>
        </Select>
      </FormControl>
    </div>
  );
};

export default StoreRolesSelect;
