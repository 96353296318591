import React, { useEffect } from 'react';
import { useFetchUserQuery } from '../../api/UserApi';
import services from '../../api/remoteServices';

interface LoginWrapperProps {
  children: React.ReactNode;
}

const RETURN_URL = `${services.stores}/Identity/Account/Login`;

const LoginWrapper = ({ children }: LoginWrapperProps): JSX.Element | null => {
  const {
    data: authData,
    isLoading: isAuthChecking,
    isSuccess,
    refetch,
  } = useFetchUserQuery();
  const isAuthorized =
    !isAuthChecking &&
    authData?.isAuthenticated &&
    !authData.isLockoutEnabled &&
    isSuccess;

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (!isAuthorized && !isAuthChecking) {
      window.location.href = process.env.REACT_APP_LOGIN_URL || RETURN_URL;
    }
  }, [isAuthorized, isAuthChecking]);

  return <>{children}</>;
};

export default LoginWrapper;
