import React, { ReactElement, useMemo, useCallback, useState } from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Chip from '@mui/joy/Chip';
import Divider from '@mui/joy/Divider';
import IconButton from '@mui/joy/IconButton';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { closeSidebar } from '../../Utils/helpers';
import { useFetchUserQuery, useLogoutMutation } from '../../api/UserApi';
import { useNavigate } from 'react-router-dom';
import { useBreadcrumb } from '../../Containers/BreadcrumbProvider';
import useSidebar from '../../Hooks/useSidebar';
import { ReactComponent as StLogo } from '../../assets/icons/stLogo.svg';
import services from '../../api/remoteServices';
import { selectUserRole } from '../../slices/userSlice';
import { useAppSelector } from '../../Hooks/reduxHooks';
import './index.scss';
import { InternalRole, ManageRoles } from '../../models/user';

interface SidebarItem {
  path?: string;
  text: string | React.ReactElement;
  icon?: React.ReactElement;
  badge?: string | number | null;
  nestedItems?: SidebarItem[];
  active?: boolean;
}

const Logo = (): React.ReactElement => {
  return <StLogo />;
};

const Sidebar: React.FC = (): ReactElement => {
  const { data: userData } = useFetchUserQuery();
  const navigate = useNavigate();
  const { setBreadcrumb } = useBreadcrumb();
  const [logout] = useLogoutMutation();
  const userImg = userData?.imageUrl;
  const userRole = useAppSelector(selectUserRole);

  const SIDEBAR_TYPE = {
    [ManageRoles.Mdm]: 'mdm-sidebar',
    [InternalRole.Internal]: 'internal-sidebar',
  } as const;

  const userSidebarType = SIDEBAR_TYPE[userRole as keyof typeof SIDEBAR_TYPE];

  const userInitial = useMemo(() => {
    if (!userImg) {
      return userData?.name?.charAt(0).toUpperCase() || '';
    }
    const imgurl = services.stores + userImg;
    return <img src={imgurl} width={40} height={'auto'} />;
  }, [userImg, userData?.name]);

  const sidebar = useSidebar(userSidebarType);

  const [openStates, setOpenStates] = useState<{ [key: string]: boolean }>({});

  const handleLogout = useCallback(async () => {
    try {
      await logout().unwrap();
      window.location.reload();
    } catch (error) {
      return null;
    }
  }, [logout]);

  const navigateTo = useCallback(
    (path?: string) => {
      if (!path) return;
      const fullPath = `${path}`;
      navigate(fullPath);

      const newBreadcrumb = path.split('/').filter(Boolean);
      setBreadcrumb(newBreadcrumb);
    },
    [navigate, setBreadcrumb],
  );

  const toggleItem = (key: string) => {
    setOpenStates((prevStates) => ({
      ...prevStates,
      [key]: !prevStates[key],
    }));
  };

  const filterActiveItems = (items: SidebarItem[]): SidebarItem[] => {
    return items
      .filter((item) => item.active)
      .map((item) => ({
        ...item,
        nestedItems: item.nestedItems
          ? filterActiveItems(item.nestedItems)
          : [],
      }));
  };

  const renderSidebarItem = (
    item: SidebarItem,
    index: number,
    parentKey: string,
    // eslint-disable-next-line no-unused-vars
    navigateTo: (path?: string) => void,
  ) => {
    const itemKey = `${parentKey}-${index}`;
    const isOpen = openStates[itemKey] || false;

    return (
      <ListItem key={itemKey} nested={!!item.nestedItems}>
        <ListItemButton onClick={() => navigateTo(item.path)}>
          {item.icon}
          <ListItemContent>
            <Typography level="title-sm">{item.text}</Typography>
          </ListItemContent>
          {item.nestedItems && item.nestedItems.length > 0 && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                toggleItem(itemKey);
              }}
            >
              <KeyboardArrowDownIcon
                sx={{ transform: isOpen ? 'rotate(180deg)' : 'none' }}
              />
            </IconButton>
          )}
          {item.badge && (
            <Chip size="sm" color="primary" variant="solid">
              {item.badge}
            </Chip>
          )}
        </ListItemButton>
        {isOpen && item.nestedItems && item.nestedItems.length > 0 && (
          <List>
            {item.nestedItems.map((nestedItem, nestedIndex) =>
              renderSidebarItem(nestedItem, nestedIndex, itemKey, navigateTo),
            )}
          </List>
        )}
      </ListItem>
    );
  };

  const sideBarItemList = useMemo(() => {
    return (
      <Box
        className="sidebar-body"
        sx={{
          [`& .${listItemButtonClasses.root}`]: {
            // gap: 1.5,
          },
        }}
      >
        <List
          size="sm"
          sx={{
            '--List-nestedInsetStart': '30px',
            '--ListItem-radius': (theme) => theme.vars.radius.sm,
          }}
        >
          {filterActiveItems(sidebar).map((item, index) =>
            renderSidebarItem(item, index, 'root', navigateTo),
          )}
        </List>
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigateTo, sidebar, openStates]);

  const sidebarFooter = useMemo(() => {
    return (
      <Box className="sidebar-footer">
        <Avatar sx={{ bgcolor: 'primary.main', marginLeft: 'auto' }}>
          {userData?.role?.charAt(0).toUpperCase() || ''}
        </Avatar>
        <Box sx={{ minWidth: 0, flex: 1 }}>
          <Typography level="body-xs">{userData?.name}</Typography>
        </Box>
        <IconButton
          size="sm"
          variant="plain"
          color="neutral"
          onClick={handleLogout}
        >
          <LogoutRoundedIcon />
        </IconButton>
      </Box>
    );
  }, [userData, handleLogout]);

  return (
    <Sheet
      className="sidebar"
      sx={{
        position: { xs: 'fixed', md: 'sticky' },
        transform: {
          xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
          md: 'none',
        },
        transition: 'transform 0.4s, width 0.4s',
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ':root': {
            '--Sidebar-width': '220px',
            [theme.breakpoints.up('lg')]: {
              '--Sidebar-width': '240px',
            },
          },
        })}
      />
      <Box
        className="sidebar-overlay"
        sx={{
          opacity: 'var(--SideNavigation-slideIn)',
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
            lg: 'translateX(-100%)',
          },
        }}
        onClick={() => closeSidebar()}
      />
      <Box className="sidebar-header">
        <IconButton
          variant="soft"
          color="primary"
          size="sm"
          onClick={() => {
            window.location.href = services.homepage;
          }}
        >
          <Logo />
        </IconButton>
        <Avatar sx={{ bgcolor: 'primary.main', marginLeft: 'auto' }}>
          {userInitial}
        </Avatar>
      </Box>

      {sideBarItemList}
      <Divider />
      {sidebarFooter}
    </Sheet>
  );
};

export default Sidebar;
