/* eslint-disable no-unused-vars */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/joy/Box';
import { Department } from '../../models/department';
import Typography from '@mui/joy/Typography';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { Button } from '@mui/joy';
import IconButton from '@mui/joy/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import Tooltip from '@mui/joy/Tooltip';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import DescriptionIcon from '@mui/icons-material/Description';
import Input from '@mui/joy/Input';
import { Category } from '../../models/category';
import { useAppSelector } from '../../Hooks/reduxHooks';
import { selectUserRole } from '../../slices/userSlice';
import { ManageRoles, InternalRole } from '../../models/user';

interface TableToolbarProps {
  numSelected: number;
  dept: string;
  category: string;
  departmentData: Department[];
  categoryData: Category[];
  keyword: string;
  selected: string[];
  resultCount: number;
  onDeptChange: (
    // eslint-disable-next-line no-unused-vars
    event:
      | React.MouseEvent<Element>
      | React.KeyboardEvent<Element>
      | React.FocusEvent<Element>
      | null,
    // eslint-disable-next-line no-unused-vars
    value: string | null,
  ) => void;
  onCategoryChange: (
    // eslint-disable-next-line no-unused-vars
    event:
      | React.MouseEvent<Element>
      | React.KeyboardEvent<Element>
      | React.FocusEvent<Element>
      | null,
    // eslint-disable-next-line no-unused-vars
    value: string | null,
  ) => void;
  onKeywordChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleClear: () => void;
  handleSearch: () => void;
  handleKeywordClear: () => void;
}

const TableToolbar: React.FC<TableToolbarProps> = (
  props: TableToolbarProps,
): React.ReactElement => {
  const {
    numSelected,
    dept,
    category,
    departmentData,
    categoryData,
    keyword,
    selected,
    onDeptChange,
    onCategoryChange,
    onKeywordChange,
    handleClear,
    handleSearch,
    handleKeywordClear,
    resultCount,
  } = props;
  const navigate = useNavigate();

  const handleEditPlu = () => {
    navigate('edit', { state: { selected } });
  };

  const handleDetail = () => {
    navigate('detail', { state: { selected } });
  };

  const ROLE_MAP = {
    [ManageRoles.Mdm]: {
      label: 'MDM Access',
      icon: (
        <IconButton onClick={handleEditPlu}>
          <InsertLinkIcon />
        </IconButton>
      ),
    },
    [InternalRole.Internal]: {
      label: 'Internal Access',
      icon: (
        <IconButton onClick={handleDetail}>
          <DescriptionIcon />
        </IconButton>
      ),
    },
  };

  const userRole = useAppSelector(selectUserRole);
  const roleInfo = ROLE_MAP[userRole as keyof typeof ROLE_MAP];

  // const handleItemMaster = () => {
  //   navigate('/admin/manage/data/itemmaster', { state: { selected } });
  // };

  return (
    <Box className="tableToolbar">
      <Box className="tableToolbar-search">
        <div className="title">
          {numSelected > 0 ? (
            <Typography
              level="title-md"
              sx={{ flex: '1 1 100%', marginLeft: '0.5rem' }}
            >
              {numSelected} selected
            </Typography>
          ) : (
            <Typography
              level="title-md"
              sx={{ flex: '1 1 100%', marginLeft: '0.5rem' }}
            >
              PLU Codes
            </Typography>
          )}
        </div>

        <Box className="departmentSelect">
          <Select
            value={dept}
            variant="soft"
            onChange={(event, value) => onDeptChange(event, value)}
          >
            <Option value="all">All</Option>
            {departmentData?.map((item) => {
              return (
                <Option
                  key={item.deptCode}
                  value={item.deptCode}
                >{`${item.nameEN} (${item.nameKO})`}</Option>
              );
            })}
          </Select>
        </Box>

        <Box className="categorySelect">
          <Select
            value={category}
            variant="soft"
            onChange={(event, value) => onCategoryChange(event, value)}
          >
            <Option value="all">All</Option>
            {categoryData?.map((item) => {
              return (
                <Option
                  key={item.categoryCode}
                  value={item.categoryCode}
                >{`${item.nameEN} (${item.nameKO})`}</Option>
              );
            })}
          </Select>
        </Box>

        <Box className="keyword">
          <Box className="keyword-input">
            <Input
              placeholder="Type Keyword"
              variant="soft"
              value={keyword}
              onChange={onKeywordChange}
            />
            {keyword && (
              <IconButton
                onClick={handleKeywordClear}
                className="clear-keyword"
              >
                <ClearIcon />
              </IconButton>
            )}
          </Box>
        </Box>

        <Button onClick={handleSearch}>Search</Button>
        <Typography
          level="body-md"
          sx={{ marginLeft: '1rem', alignSelf: 'center' }}
        >
          {resultCount} results
        </Typography>
      </Box>

      {numSelected > 0 ? (
        <div className="linkButtonGroup">
          <Button variant="outlined" size="sm" onClick={handleClear}>
            Clear
          </Button>
          <Tooltip title={roleInfo.label}>
            <IconButton>{roleInfo.icon}</IconButton>
          </Tooltip>
          {/* <Button onClick={handleItemMaster}>Item Master Test</Button> */}
        </div>
      ) : null}
    </Box>
  );
};

export default TableToolbar;
