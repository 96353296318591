import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Stack from '@mui/joy/Stack';
import Option from '@mui/joy/Option';
import Typography from '@mui/joy/Typography';
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import CardOverflow from '@mui/joy/CardOverflow';
import Select, { SelectStaticProps } from '@mui/joy/Select';
import { Chip } from '@mui/joy';
import Avatar from '@mui/joy/Avatar';
import Radio, { radioClasses } from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import Sheet from '@mui/joy/Sheet';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ConfirmationSnackbar from '../ConfirmationSnackbar/index';
import { useFetchUserQuery, useFetchMDMUserQuery } from '../../api/UserApi';
import { useGetDepartmentsQuery } from '../../api/DepartmentApi';
import { useSendMessageMutation } from '../../api/MessageApi';
import { useLazyGetCategoriesQuery } from '../../api/CategoryApi';
import services from '../../api/remoteServices';
import Autocomplete from '@mui/joy/Autocomplete';
import { UserRole } from '../../models/user';
import MessageEditor from '../MessageEditor';
import { EditorState } from 'draft-js';
import { getContentLength } from '../../Utils/helpers';

const PluCodeRequest: React.FC = (): React.ReactElement => {
  const { data: authData } = useFetchUserQuery();
  const { data: mdmUserData } = useFetchMDMUserQuery();
  const { data: departmentData } = useGetDepartmentsQuery();
  const [sendMessage] = useSendMessageMutation();
  const [dept, setDept] = useState<string>('all');
  const [category, setCategory] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [fileNames, setFileNames] = useState<string[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [sendTo, setSendTo] = useState<string | null>(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'danger'
  >('success');
  const action: SelectStaticProps['action'] = useRef(null);
  const userId = authData?.id;
  const charLimit = 500;

  const [triggerGetCategories, { data: categoriesData }] =
    useLazyGetCategoriesQuery();

  useEffect(() => {
    const param = {
      deptCode: dept,
    };

    if (dept) {
      triggerGetCategories(param);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dept]);

  const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCategory(event.target.value);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(event.target.files || []);
    setFiles(selectedFiles);
    const names = selectedFiles.map((file) => file.name);
    setFileNames(names);
  };

  const resetEditor = () => {
    setMessage('');
    setEditorState(EditorState.createEmpty());
  };

  const handleRequestPluMessage = () => {
    if (message.trim()) {
      const formData = new FormData();
      formData.append('message', message);
      formData.append('userId', userId!);
      formData.append('dept', dept);
      formData.append('category', category);
      formData.append('sendTo', sendTo || 'defaultUserId');

      if (files.length > 0) {
        files.forEach((file) => {
          formData.append('files', file);
        });
      }

      sendMessage(formData)
        .unwrap()
        .then((response) => {
          const typedResponse = response as unknown as { message: string };
          setDept('all');
          setCategory('');
          setFiles([]);
          setFileNames([]);
          setFiles([]);
          setSnackbarMessage(typedResponse.message);
          setSnackbarSeverity('success');
          setOpenSnackbar(true);
          resetEditor();
        });
    }
  };

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (
      !category ||
      getContentLength(editorState) == 0 ||
      getContentLength(editorState) > charLimit
    )
      return;

    handleRequestPluMessage();
  };

  return (
    <div className="plucodeRequestForm">
      <Box sx={{ flex: 1, width: '100%' }}>
        <Box
          sx={{
            position: 'sticky',
            top: { sm: -100, md: -110 },
            bgcolor: 'background.body',
            zIndex: 9995,
          }}
        >
          <Box sx={{ px: { xs: 2, md: 6 } }}>
            <Typography level="h2" component="h1" sx={{ mt: 1, mb: 2 }}>
              Plu Request
            </Typography>
          </Box>
        </Box>
        <Stack
          spacing={4}
          sx={{
            display: 'flex',
            mx: 'auto',
            px: { xs: 2, md: 6 },
            py: { xs: 2, md: 3 },
          }}
        >
          <Card sx={{ pb: 0 }}>
            <form onSubmit={handleFormSubmit}>
              <Box sx={{ mb: 1 }}>
                <Typography level="title-md">
                  수정/새로운 PLU 코드를 요청하기 위한 필요한 세부 정보를 제공해
                  주세요. <br /> (Please provide the necessary details to
                  request a modified/new PLU code.)
                </Typography>
              </Box>
              <Divider />
              <Stack
                direction="row"
                spacing={3}
                sx={{ display: { xs: 'none', md: 'flex' }, my: 1 }}
              >
                <Stack spacing={2} sx={{ flexGrow: 1 }}>
                  <Stack spacing={1}>
                    <FormLabel>타입 Request Type</FormLabel>
                    <FormControl
                      sx={{
                        display: { sm: 'flex-column', md: 'flex-row' },
                        gap: 2,
                      }}
                    >
                      <Select
                        disabled={true}
                        multiple
                        size="sm"
                        defaultValue={['plureq']}
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', gap: '0.25rem' }}>
                            {selected.map((selectedOption, index) => (
                              <Chip
                                key={index}
                                variant="soft"
                                color="primary"
                                size="sm"
                              >
                                {selectedOption.label}
                              </Chip>
                            ))}
                          </Box>
                        )}
                        sx={{
                          minWidth: '15rem',
                        }}
                        slotProps={{
                          listbox: {
                            sx: {
                              width: '100%',
                            },
                          },
                        }}
                      >
                        <Option value="plureq">PLU REQ</Option>
                      </Select>
                    </FormControl>
                  </Stack>
                  <Stack spacing={2} sx={{ mb: 2 }}>
                    <div>
                      <FormControl sx={{ display: { sm: 'contents' } }}>
                        <FormLabel>관련 부서 Department</FormLabel>
                        <Select
                          size="sm"
                          variant="outlined"
                          action={action}
                          value={dept}
                          placeholder="Department"
                          required
                          onChange={(e, newValue) => setDept(newValue || '')}
                          sx={{ minWidth: 160 }}
                        >
                          {departmentData?.map((item) => {
                            return (
                              <Option
                                key={item.deptCode}
                                value={item.deptCode}
                              >{`${item.nameEN} (${item.nameKO})`}</Option>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                  </Stack>
                  <Stack spacing={2} sx={{ mb: 2 }}>
                    <div>
                      <FormControl sx={{ display: { sm: 'contents' } }}>
                        <FormLabel>보내는 사람 Send To</FormLabel>
                        <Autocomplete
                          placeholder="Type @ to search email"
                          required
                          options={
                            Array.isArray(mdmUserData)
                              ? mdmUserData.map((user: UserRole) => ({
                                  label: `${user.name} (${user.email})`,
                                  value: user.id,
                                }))
                              : []
                          }
                          onInputChange={(e, value) => {
                            if (value.startsWith('@')) {
                              setSendTo(value);
                            } else {
                              setSendTo(null);
                            }
                          }}
                          onChange={(e, value) =>
                            setSendTo((value as { value: string })?.value || '')
                          }
                          filterOptions={(options, { inputValue }) => {
                            if (inputValue.startsWith('@')) {
                              return options.filter((option) =>
                                option.label
                                  .toLowerCase()
                                  .includes(
                                    inputValue.substring(1).toLowerCase(),
                                  ),
                              );
                            }
                            return [];
                          }}
                        />
                      </FormControl>
                    </div>
                  </Stack>
                </Stack>
              </Stack>
              <Stack spacing={1} sx={{ mt: 2, mb: 2, flexGrow: 1 }}>
                <FormControl>
                  <FormLabel>카테고리 Categories</FormLabel>
                </FormControl>
                {!category && (
                  <Typography color="danger">
                    Please Pick a Category.
                  </Typography>
                )}
                <RadioGroup
                  aria-label="category"
                  defaultValue=""
                  value={category}
                  onChange={handleCategoryChange}
                  overlay
                  name="category"
                  sx={{
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap',
                    gap: 1.5,
                    [`& .${radioClasses.checked}`]: {
                      [`& .${radioClasses.action}`]: {
                        inset: -1,
                        border: '3px solid',
                        borderColor: 'primary.500',
                      },
                    },
                    [`& .${radioClasses.radio}`]: {
                      display: 'contents',
                      '& > svg': {
                        zIndex: 2,
                        position: 'absolute',
                        top: '-8px',
                        right: '-8px',
                        bgcolor: 'background.surface',
                        borderRadius: '50%',
                      },
                    },
                  }}
                >
                  {categoriesData?.map((value) => (
                    <Sheet
                      key={value.categoryCode}
                      variant="outlined"
                      sx={{
                        borderRadius: 'md',
                        boxShadow: 'sm',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 1.5,
                        p: 2,
                        minWidth: 125,
                      }}
                    >
                      <Radio
                        id={value.categoryCode}
                        value={value.categoryCode}
                        checkedIcon={<CheckCircleRoundedIcon />}
                      />

                      <Avatar
                        alt={value?.nameEN}
                        src={`${services.stores}/${value.imageUrl}`}
                        size="lg"
                      />

                      <FormLabel
                        htmlFor={value.categoryCode}
                        sx={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          flexDirection: 'column',
                        }}
                      >
                        <Typography level="title-sm">{value.nameKO}</Typography>
                        <Typography level="body-xs">{value.nameEN}</Typography>
                      </FormLabel>
                    </Sheet>
                  ))}
                </RadioGroup>
              </Stack>
              <Stack spacing={1} sx={{ mb: 2 }}>
                <FormLabel>Message</FormLabel>
                <MessageEditor
                  setMessage={setMessage}
                  editorState={editorState}
                  setEditorState={setEditorState}
                />
                <Typography>
                  {getContentLength(editorState)}/{charLimit} characters
                </Typography>
                <Typography color="danger">
                  {getContentLength(editorState) === 0
                    ? 'Please enter a message.'
                    : getContentLength(editorState) > charLimit
                    ? `Please limit to ${charLimit} characters.`
                    : null}
                </Typography>
                <input
                  type="file"
                  multiple
                  onChange={handleFileChange}
                  style={{ marginTop: '10px' }}
                />
                <div className="file-names">
                  {fileNames.length > 0 ? (
                    <p>Files attached: {fileNames.join(', ')}</p>
                  ) : null}
                </div>
              </Stack>
              <CardOverflow
                sx={{ borderTop: '1px solid', borderColor: 'divider' }}
              >
                <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
                  <Button size="sm" variant="solid" type="submit">
                    Submit
                  </Button>
                </CardActions>
              </CardOverflow>
            </form>
          </Card>
        </Stack>
      </Box>
      <ConfirmationSnackbar
        message={snackbarMessage}
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        color={snackbarSeverity}
      />
    </div>
  );
};

export default PluCodeRequest;
