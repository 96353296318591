import React, { ReactElement } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from 'react-router-dom';
import { StyledEngineProvider } from '@mui/joy/styles';
import LoginWrapper from '../LoginWrapper/index';
import InternalLayout from '../../layout/InternalLayout';
import MdmLayout from '../../layout/Mdm';

interface RouteParams {
  storeSlug?: string;
  [key: string]: string | undefined;
}

const Internal: React.FC<{ storeSlug: string }> = ({ storeSlug }) => {
  return (
    <LoginWrapper>
      <InternalLayout storeSlug={storeSlug} />
    </LoginWrapper>
  );
};

const Mdm: React.FC = (): ReactElement => {
  return (
    <LoginWrapper>
      <MdmLayout />
    </LoginWrapper>
  );
};

const LandingPage: React.FC = (): ReactElement => {
  return (
    <div>
      <h2>Landing Page</h2>
    </div>
  );
};

const App: React.FC = (): ReactElement => {
  return (
    <Router basename="/">
      <Routes>
        <Route
          path="/:storeSlug/manage/*"
          element={<InternalComponent component={Internal} />}
        />
        <Route
          path="/mdm/manage/*"
          element={<AdminComponent component={Mdm} />}
        />
        <Route path="/" element={<LandingPage />} />
      </Routes>
    </Router>
  );
};

const AdminComponent: React.FC<{
  component: React.FC;
}> = ({ component: Component }) => {
  return <Component />;
};

const InternalComponent: React.FC<{
  component: React.FC<{ storeSlug: string }>;
}> = ({ component: Component }) => {
  const { storeSlug } = useParams<RouteParams>();
  const slug = storeSlug || 'defaultSlug';
  return <Component storeSlug={slug} />;
};

const WrappedApp: React.FC = (): ReactElement => {
  return (
    <StyledEngineProvider injectFirst>
      <App />
    </StyledEngineProvider>
  );
};

export default WrappedApp;
