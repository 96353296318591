import React, { ReactElement } from 'react';
import { Reply } from '../../../models/notificationDetail';
import ReplyHeader from './replyHeader';
import DOMPurify from 'dompurify';
import FileAttachment from './fileAttachment';
import Sheet from '@mui/joy/Sheet';
import Divider from '@mui/joy/Divider';

type RepliesProps = {
  replies: Reply[];
  userId?: string;
};

const ReplyPane: React.FC<RepliesProps> = ({
  replies,
  userId,
}): ReactElement => {
  const sanitizeMessage = (messageText: string) => {
    return messageText ? DOMPurify.sanitize(messageText) : '';
  };

  return (
    <div className="reply">
      {replies?.map((reply) => {
        return (
          <Sheet
            key={reply.messageId}
            className="reply-container"
            variant="outlined"
            sx={{ borderRadius: 'sm' }}
          >
            <ReplyHeader
              replyUser={reply.replyUser}
              timeStamp={reply.createdAt}
              userId={userId}
              sentAt={reply.sentAt}
            />
            <div className="replyBody">
              {reply.messageText ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizeMessage(reply.messageText),
                  }}
                />
              ) : (
                <p>No message available.</p>
              )}
            </div>
            {reply.fileAttachments.length > 0 ? (
              <div className="replyAttachments">
                <Divider />
                <FileAttachment attachments={reply.fileAttachments || []} />
              </div>
            ) : null}
          </Sheet>
        );
      })}
    </div>
  );
};

export default ReplyPane;
