// src/store.ts
import { configureStore } from '@reduxjs/toolkit';
import { userApi } from './api/UserApi';
import { StoresApi } from './api/StoresApi';
import { MessageApi } from './api/MessageApi';
import { messageReducer } from './slices/messageSlice';
import * as user from './slices/userSlice';
import { CodeApi } from './api/CodeApi';
import { CategoryApi } from './api/CategoryApi';
import { DepartmentApi } from './api/DepartmentApi';
import { BranchItemMasterApi } from './api/BranchItemMasterApi';

export const store = configureStore({
  reducer: {
    user: user.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [StoresApi.reducerPath]: StoresApi.reducer,
    [MessageApi.reducerPath]: MessageApi.reducer,
    [CodeApi.reducerPath]: CodeApi.reducer,
    [CategoryApi.reducerPath]: CategoryApi.reducer,
    [DepartmentApi.reducerPath]: DepartmentApi.reducer,
    [BranchItemMasterApi.reducerPath]: BranchItemMasterApi.reducer,
    message: messageReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      userApi.middleware,
      StoresApi.middleware,
      MessageApi.middleware,
      CodeApi.middleware,
      CategoryApi.middleware,
      DepartmentApi.middleware,
      BranchItemMasterApi.middleware,
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
