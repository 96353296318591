import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { User, UserRole } from '../models/user';
import services from './remoteServices';

export type UserParamsType = {
  storeUrl: string;
};

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${services.stores}/api/`,
    credentials: 'include',
  }),
  tagTypes: ['user'],
  endpoints: (builder) => ({
    fetchUser: builder.query<User, void>({
      query: () => ({
        url: `auth/CheckAuth`,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      providesTags: ['user'],
    }),
    fetchMDMUser: builder.query<UserRole, void>({
      query: () => ({
        url: `user/getmdmusers`,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      providesTags: ['user'],
    }),
    fetchUserTest: builder.query<User, UserParamsType>({
      query: (params) => ({
        url: `auth/CheckAuth/${params.storeUrl}`,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      providesTags: ['user'],
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        url: `auth/Logout`,
        method: 'POST',
      }),
    }),
    uploadUserImage: builder.mutation<{ imageUrl: string }, FormData>({
      query: (formData) => ({
        url: `user/updateUserProfile`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['user'],
    }),
  }),
});

export const {
  useFetchUserQuery,
  useFetchMDMUserQuery,
  useLogoutMutation,
  useUploadUserImageMutation,
} = userApi;
