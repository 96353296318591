import React, { useState, useRef, useEffect, useMemo } from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab, { tabClasses } from '@mui/joy/Tab';
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import CardOverflow from '@mui/joy/CardOverflow';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import Avatar from '@mui/joy/Avatar';
import {
  useFetchUserQuery,
  useUploadUserImageMutation,
} from '../../api/UserApi';
import StoreRolesSelect from './storeRolesSelect';
import ConfirmationSnackbar from '../ConfirmationSnackbar';
import services from '../../api/remoteServices';

const MyProfile: React.FC = (): React.ReactElement | null => {
  const { data: userData } = useFetchUserQuery();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedFileName, setSelectedFileName] = useState<string | null>(null);
  const [userName, setUserName] = useState<string>('');
  const [storeRoles, setStoreRoles] = useState<string[]>([]);
  const [uploadUserImage] = useUploadUserImageMutation();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const imageUrl = userData?.imageUrl
    ? `${services.stores}${userData.imageUrl}`
    : '';

  useEffect(() => {
    if (userData) {
      setUserName(userData.name || '');

      const storeRolesList =
        userData?.storeRoles?.map((item) => item.role.toLowerCase()) || [];
      setStoreRoles(storeRolesList);
    }
  }, [userData]);

  const userInitial = useMemo(() => {
    if (!imageUrl) {
      return (
        <Avatar size="lg" sx={{ bgcolor: 'primary.main', marginLeft: 'auto' }}>
          {userData?.name?.charAt(0).toUpperCase() || ''}
        </Avatar>
      );
    }

    return <img src={imageUrl} loading="lazy" alt="user initial" />;
  }, [imageUrl, userData?.name]);

  const handleFileChange = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];
      setSelectedFileName(event.target.files[0].name);
      setSelectedFile(selectedFile);
    }
  };

  const handleSave = async () => {
    const formData = new FormData();
    formData.append('userId', userData?.id || '');
    formData.append('userName', userName);

    if (selectedFile) {
      formData.append('file', selectedFile);
    }

    try {
      await uploadUserImage(formData).unwrap();
      setOpenSnackbar(true);
    } catch (error) {
      // console.error('Failed to upload image:', error);
    }
  };

  const handleCancel = () => {
    setUserName(userData?.name || '');
    setSelectedFileName(null);
    setSelectedFile(null);
  };

  return userData ? (
    <Box sx={{ flex: 1, width: '100%' }}>
      <Box
        sx={{
          position: 'sticky',
          top: { sm: -100, md: -110 },
          bgcolor: 'background.body',
          zIndex: 9995,
        }}
      >
        <Tabs
          defaultValue={0}
          sx={{
            bgcolor: 'transparent',
          }}
        >
          <TabList
            tabFlex={1}
            size="sm"
            sx={{
              pl: { xs: 0, md: 4 },
              justifyContent: 'left',
              [`&& .${tabClasses.root}`]: {
                fontWeight: '600',
                flex: 'initial',
                color: 'text.tertiary',
                [`&.${tabClasses.selected}`]: {
                  bgcolor: 'transparent',
                  color: 'text.primary',
                  '&::after': {
                    height: '2px',
                    bgcolor: 'primary.500',
                  },
                },
              },
            }}
          >
            <Tab sx={{ borderRadius: '6px 6px 0 0' }} indicatorInset value={0}>
              Settings
            </Tab>
            {/* <Tab sx={{ borderRadius: '6px 6px 0 0' }} indicatorInset value={1}>
              Team
            </Tab> */}
          </TabList>
        </Tabs>
      </Box>
      <Stack
        spacing={4}
        sx={{
          display: 'flex',
          maxWidth: '800px',
          mx: 'auto',
          px: { xs: 2, md: 6 },
          py: { xs: 2, md: 3 },
        }}
      >
        <Card>
          <Box sx={{ mb: 1 }}>
            <Typography level="title-md">My Profile</Typography>
            <Typography level="body-sm">
              Customize how your profile information will apper to the networks.
            </Typography>
          </Box>
          <Divider />
          <Stack
            direction="row"
            spacing={3}
            sx={{ display: { xs: 'flex', md: 'flex' }, my: 1 }}
          >
            <Stack direction="column" spacing={1}>
              <AspectRatio
                ratio="1"
                maxHeight={200}
                sx={{ flex: 1, minWidth: 120, borderRadius: '100%' }}
              >
                {userInitial}
              </AspectRatio>

              <IconButton
                aria-label="upload new picture"
                size="sm"
                variant="outlined"
                color="neutral"
                sx={{
                  bgcolor: 'background.body',
                  position: 'absolute',
                  zIndex: 2,
                  borderRadius: '50%',
                  left: 100,
                  top: 170,
                  boxShadow: 'sm',
                }}
                onClick={handleFileChange}
              >
                <EditRoundedIcon />
              </IconButton>
            </Stack>
            <Stack spacing={2} sx={{ flexGrow: 1 }}>
              <Stack spacing={1}>
                <FormLabel>Display Name</FormLabel>
                <FormControl
                  sx={{
                    display: { sm: 'flex-column', md: 'flex-row' },
                    gap: 2,
                  }}
                >
                  <Input
                    size="sm"
                    placeholder="Display Name"
                    sx={{ flexGrow: 1 }}
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </FormControl>
              </Stack>
              <Stack direction="row" spacing={2}>
                <FormControl>
                  <FormLabel>Role</FormLabel>
                  <Input
                    size="sm"
                    defaultValue={userData?.role}
                    disabled={true}
                  />
                </FormControl>
                <FormControl sx={{ flexGrow: 1 }}>
                  <FormLabel>Email</FormLabel>
                  <Input
                    size="sm"
                    type="email"
                    disabled={true}
                    startDecorator={<EmailRoundedIcon />}
                    placeholder="email"
                    defaultValue={userData?.email}
                    sx={{ flexGrow: 1 }}
                  />
                </FormControl>
              </Stack>
              <div>
                <StoreRolesSelect storeRoles={storeRoles} />
              </div>
              {/* <div>
                <CountrySelector />
              </div> */}
              <div>
                <input
                  type="file"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={handleFileSelect}
                />
                {selectedFileName && (
                  <Typography level="body-sm" sx={{ mt: 2 }}>
                    Selected file: {selectedFileName}
                  </Typography>
                )}
              </div>
            </Stack>
          </Stack>
          <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
            <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
              <Button
                size="sm"
                variant="outlined"
                color="neutral"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button size="sm" variant="solid" onClick={handleSave}>
                Save
              </Button>
            </CardActions>
          </CardOverflow>
        </Card>
      </Stack>
      <ConfirmationSnackbar
        message={`${userData?.name}'s profile is updated successfully`}
        open={openSnackbar}
        setOpen={setOpenSnackbar}
      />
    </Box>
  ) : null;
};

export default MyProfile;
