/* eslint-disable no-unused-vars */
import React from 'react';
import Stack from '@mui/joy/Stack';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import { Box, Chip, IconButton, Input } from '@mui/joy';
import List from '@mui/joy/List';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import NotificationListItem from './notificationListItem';
import { NotificationProps } from '../../models/notification';
import { toggleMessagesPane } from '../../Utils/helpers';
import { useAppSelector } from '../../Hooks/reduxHooks';
import { selectMessageCount } from '../../slices/messageSlice';

type MessagePaneProps = {
  messages: NotificationProps[];
  setSelectedChat: (chat: NotificationProps) => void;
  selectedChatId: string;
};

const MessagePane: React.FC<MessagePaneProps> = (
  props: MessagePaneProps,
): React.ReactElement => {
  const { messages, setSelectedChat, selectedChatId } = props;
  const unReadMessageCount = useAppSelector(selectMessageCount);

  return (
    <Sheet
      sx={{
        borderRight: '1px solid',
        borderColor: 'divider',
        // height: 'calc(100% - 3rem)',
        overflowY: 'auto',
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
        p={2}
        pb={1.5}
      >
        <Typography
          fontSize={{ xs: 'md', md: 'lg' }}
          component="h1"
          fontWeight="lg"
          endDecorator={
            <Chip
              variant="soft"
              color="primary"
              size="md"
              slotProps={{ root: { component: 'span' } }}
            >
              {unReadMessageCount}
            </Chip>
          }
          sx={{ mr: 'auto' }}
        >
          Messages
        </Typography>
        <IconButton
          variant="plain"
          aria-label="edit"
          color="neutral"
          size="sm"
          onClick={() => {
            toggleMessagesPane();
          }}
          sx={{ display: { sm: 'none' } }}
        >
          <CloseRoundedIcon />
        </IconButton>
      </Stack>
      <Box sx={{ px: 2, pb: 1.5 }}>
        <Input
          size="sm"
          startDecorator={<SearchRoundedIcon />}
          placeholder="Search"
          aria-label="Search"
        />
      </Box>
      <List
        sx={{
          py: 0,
          '--ListItem-paddingY': '0.75rem',
          '--ListItem-paddingX': '1rem',
        }}
      >
        {messages?.map((content) => (
          <NotificationListItem
            key={content.id}
            {...content}
            setSelectedChat={setSelectedChat}
            selectedChatId={selectedChatId}
          />
        ))}
      </List>
    </Sheet>
  );
};

export default MessagePane;
