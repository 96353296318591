/* eslint-disable no-unused-vars */
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Editor, EditorState, RichUtils, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'draft-js/dist/Draft.css';
import IconButton from '@mui/joy/IconButton';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import Box from '@mui/joy/Box';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import './index.scss';

type MessageEditorProps = {
  setMessage: (message: string) => void;
  editorState: EditorState;
  setEditorState: (state: EditorState) => void;
};

const MessageEditor: React.FC<MessageEditorProps> = ({
  setMessage,
  editorState,
  setEditorState,
}): ReactElement => {
  const editorRef = useRef<Editor | null>(null);
  const [charCount, setCharCount] = useState(0);

  const onChange = (newState: EditorState) => {
    const contentState = newState.getCurrentContent();
    const plainText = contentState.getPlainText();
    if (setCharCount) {
      setCharCount(plainText.length);
    }
    setEditorState(newState);
    setMessage(draftToHtml(convertToRaw(contentState)));
  };

  useEffect(() => {
    if (editorState) {
      const contentState = editorState.getCurrentContent();
      const message = draftToHtml(convertToRaw(contentState));
      setMessage(message);
      setCharCount(message.length);
    }
  }, [editorState, setMessage]);

  const toggleBlockType = (blockType: string) => {
    const newState = RichUtils.toggleBlockType(editorState, blockType);
    onChange(newState);
  };

  const isBlockTypeActive = (blockType: string) => {
    const selection = editorState.getSelection();
    const currentBlockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();
    return currentBlockType === blockType;
  };

  const handleDivClick = () => {
    if (editorRef.current) {
      editorRef.current.focus();
    }
  };

  return (
    <div className="editor" onClick={handleDivClick}>
      <Box className="options">
        <IconButton
          variant={isBlockTypeActive('header-three') ? 'solid' : 'outlined'}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            toggleBlockType('header-three');
          }}
        >
          <FormatBoldIcon />
        </IconButton>
        <IconButton
          variant={
            isBlockTypeActive('unordered-list-item') ? 'solid' : 'outlined'
          }
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            toggleBlockType('unordered-list-item');
          }}
        >
          <FormatListBulletedIcon />
        </IconButton>
        <IconButton
          variant={
            isBlockTypeActive('ordered-list-item') ? 'solid' : 'outlined'
          }
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            toggleBlockType('ordered-list-item');
          }}
        >
          <FormatListNumberedIcon />
        </IconButton>
      </Box>

      <div className="editorBox">
        <Editor editorState={editorState} onChange={onChange} ref={editorRef} />
      </div>
    </div>
  );
};

export default MessageEditor;
