import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Category } from '../models/category';
import services from './remoteServices';

export type CategoryParamsType = {
  deptCode: string;
};

export const CategoryApi = createApi({
  reducerPath: 'CategoryApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${services.stores}/api/`,
    credentials: 'include',
  }),
  tagTypes: ['category'],
  endpoints: (builder) => ({
    getCategories: builder.query<Category[], CategoryParamsType>({
      query: (params) => ({
        url: `category/getall?deptCode=${params.deptCode}`,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      providesTags: ['category'],
    }),
  }),
});

export const { useGetCategoriesQuery, useLazyGetCategoriesQuery } = CategoryApi;
