import { useMemo } from 'react';
import { useAppSelector } from './reduxHooks';
import { ManageRoles } from '../models/user';

const useIsUserAuthorized = (): boolean => {
  const userRole = useAppSelector((state) => state.user.role);

  const isUserAuthorized = useMemo(() => {
    return userRole === ManageRoles.Mdm;
  }, [userRole]);

  return isUserAuthorized;
};

export default useIsUserAuthorized;
