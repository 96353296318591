import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';
import Table from '@mui/joy/Table';
import Chip from '@mui/joy/Chip';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import MessagesPaneHeader from './messagePaneHeader';
import { NotificationProps } from '../../models/notification';
import Typography from '@mui/joy/Typography';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import { useAppSelector } from '../../Hooks/reduxHooks';
import { selectManageRoles, selectStoreUrl } from '../../slices/userSlice';

type MessagesPaneProps = {
  chat: NotificationProps;
};

const MESSAGE_STATUS = {
  true: {
    color: 'danger',
    text: 'pending',
    icon: <PendingActionsIcon />,
  },
  false: {
    color: 'success',
    text: 'completed',
    icon: <CheckRoundedIcon />,
  },
} as const;

const MessagesPane: React.FC<MessagesPaneProps> = (
  props: MessagesPaneProps,
): React.ReactElement => {
  const isUserManageRole = useAppSelector(selectManageRoles);
  const storeUrl = useAppSelector(selectStoreUrl);
  const { chat } = props;
  const [rows, setRows] = useState(chat);
  const navigate = useNavigate();

  const stripHtmlTags = (html: string) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  };

  useEffect(() => {
    setRows(chat);
  }, [chat]);

  const handleNavigateToMessageConfirmation = (id: string) => {
    if (isUserManageRole)
      return navigate(`/mdm/manage/message/confirmation/${id}`);

    return navigate(`/${storeUrl}/manage/message/confirmation/${id}`);
  };

  return (
    <Sheet
      className="messageList"
      sx={{ display: 'flex', flexDirection: 'column' }}
    >
      <MessagesPaneHeader sender={chat.sender} />
      <Box
        sx={{
          padding: 1,
          px: 2,
          py: 3,
        }}
      >
        <Table
          noWrap
          variant="outlined"
          hoverRow
          stickyHeader
          sx={{ mx: 'auto' }}
        >
          <thead>
            <tr>
              <th>Id</th>
              <th>Queue Type</th>
              <th>Send To</th>
              <th>SentAt</th>
              <th>Content</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {rows.messages.map((row) => {
              const unreadStatus = row.unread;
              return (
                <tr
                  key={row.id}
                  onClick={() => handleNavigateToMessageConfirmation(row.id)}
                  style={{ cursor: 'pointer' }}
                >
                  <td>{row.id}</td>
                  <td>{row.queueType}</td>
                  <td>
                    <Typography
                      color={'primary'}
                      variant="plain"
                      fontWeight={600}
                    >
                      {row.sendTo}
                    </Typography>
                  </td>
                  <td>{row.timestamp}</td>
                  <td>{stripHtmlTags(row.content)}</td>
                  <td>
                    <Chip
                      size="sm"
                      variant="soft"
                      startDecorator={
                        MESSAGE_STATUS[unreadStatus ? 'true' : 'false'].icon
                      }
                      color={
                        MESSAGE_STATUS[unreadStatus ? 'true' : 'false'].color
                      }
                    >
                      {MESSAGE_STATUS[unreadStatus ? 'true' : 'false'].text}
                    </Chip>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Box>
    </Sheet>
  );
};

export default MessagesPane;
