import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Department } from '../models/department';
import services from './remoteServices';

export type CategoryParamsType = {
  dept: string;
};

export const DepartmentApi = createApi({
  reducerPath: 'DepartmentApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${services.stores}/api/`,
    credentials: 'include',
  }),
  tagTypes: ['department'],
  endpoints: (builder) => ({
    getDepartments: builder.query<Department[], void>({
      query: () => ({
        url: `department/getall`,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      providesTags: ['department'],
    }),
  }),
});

export const { useGetDepartmentsQuery, useLazyGetDepartmentsQuery } =
  DepartmentApi;
