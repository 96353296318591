import React from 'react';
import OrderTable from './orderTable';
import OrderList from './orderList';
import './index.scss';

const Order: React.FC = (): React.ReactElement => {
  return (
    <div className="purchaseOrder">
      <OrderTable />
      <OrderList />
    </div>
  );
};

export default Order;
