import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { StoreProduct } from '../models/storeProduct';
import services from './remoteServices';

export type StoreProductParamsType = {
  urlSlug: string;
  includeProperties: string;
};

export const StoresApi = createApi({
  reducerPath: 'StoresApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${services.stores}/api/`,
    credentials: 'include',
  }),
  tagTypes: ['StoreProduct'],
  endpoints: (builder) => ({
    getStoreProducts: builder.query<StoreProduct[], StoreProductParamsType>({
      query: (params) => ({
        url: `${params.urlSlug}/products?includeProperties=${params.includeProperties}`,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      providesTags: ['StoreProduct'],
    }),
  }),
});

export const { useGetStoreProductsQuery } = StoresApi;
