import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BranchItemMaster } from '../models/item';
import services from './remoteServices';

export type ItemMasterParamType = {
  itemCode: string;
};

export const BranchItemMasterApi = createApi({
  reducerPath: 'BranchItemMaster',
  baseQuery: fetchBaseQuery({
    baseUrl: `${services.stores}/api/`,
    credentials: 'include',
  }),
  tagTypes: ['itemMaster'],
  endpoints: (builder) => ({
    searchItemMasterDetail: builder.query<
      BranchItemMaster,
      ItemMasterParamType
    >({
      query: (params) => ({
        url: `/itemmaster/detail/${params.itemCode}`,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      providesTags: ['itemMaster'],
    }),
    createFile: builder.mutation<void, void>({
      query: (params) => ({
        url: `/itemmaster/create`,
        method: 'POST',
        body: params,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
  }),
});

export const { useSearchItemMasterDetailQuery, useCreateFileMutation } =
  BranchItemMasterApi;
