import React, { forwardRef } from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import services from '../../api/remoteServices';

export interface SenderProfileProps {
  name?: string;
  userName?: string;
  online?: boolean;
  avatar?: string;
  storeRole?: string;
  storeName?: string;
}

const UserCard = forwardRef<HTMLDivElement, SenderProfileProps>(
  (props, ref) => {
    const { name, userName, storeName, avatar, storeRole } = props;

    return (
      <Box
        ref={ref}
        tabIndex={0}
        sx={{
          width: '600px',
          position: 'relative',
          overflow: { xs: 'auto', sm: 'initial' },
        }}
      >
        <Box />
        <Card
          orientation="horizontal"
          sx={{
            width: '100%',
            flexWrap: 'wrap',
            [`& > *`]: {
              '--stack-point': '500px',
              minWidth:
                'clamp(0px, (calc(var(--stack-point) - 2 * var(--Card-padding) - 2 * var(--variant-borderWidth, 0px)) + 1px - 100%) * 999, 100%)',
            },
            overflow: 'auto',
          }}
        >
          <AspectRatio flex ratio="1" maxHeight={182} sx={{ minWidth: 182 }}>
            <img src={`${services.stores}/${avatar}`} loading="lazy" alt="" />
          </AspectRatio>
          <CardContent>
            <Typography sx={{ fontSize: 'xl', fontWeight: 'lg' }}>
              {name}
            </Typography>
            <Typography
              level="body-sm"
              textColor="text.tertiary"
              sx={{ fontWeight: 'lg' }}
            >
              Store Role: {storeRole}
            </Typography>
            <Typography
              level="body-sm"
              textColor="text.tertiary"
              sx={{ fontWeight: 'lg' }}
            >
              {userName}
            </Typography>
            <Sheet
              sx={{
                bgcolor: 'background.level1',
                borderRadius: 'sm',
                p: 1.5,
                my: 1.5,
                display: 'flex',
                gap: 2,
                '& > div': { flex: 1 },
              }}
            >
              <div>
                <Typography level="body-xs" sx={{ fontWeight: 'lg' }}>
                  Store
                </Typography>
                <Typography sx={{ fontWeight: 'lg' }}>{storeName}</Typography>
              </div>
              <div>
                <Typography level="body-xs" sx={{ fontWeight: 'lg' }}>
                  Completed
                </Typography>
                <Typography sx={{ fontWeight: 'lg' }}>37</Typography>
              </div>
              <div>
                <Typography level="body-xs" sx={{ fontWeight: 'lg' }}>
                  Pending
                </Typography>
                <Typography sx={{ fontWeight: 'lg' }}>3</Typography>
              </div>
            </Sheet>
          </CardContent>
        </Card>
      </Box>
    );
  },
);

export default UserCard;
